import * as React from 'react';
import PageHeader from '../../components/page-header/PageHeader';
import Login from '../../components/login/Login';
import ResetPassword from '../../components/reset-password/ResetPassword';

import style from './ResetPasswordPage.module.css';

const ResetPasswordPage = () => {
  return (
    <div id={style.container}>
      <PageHeader className={style.pageHeader}></PageHeader>
      <img src={'/login-page-banner.png'} id={style.banner}></img>
      <ResetPassword></ResetPassword>
    </div>
  );
};

export default ResetPasswordPage;
