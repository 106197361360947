import * as React from 'react';
import { Navigate, useParams } from 'react-router';

const Redirector = () => {
  const params = useParams();

  return <Navigate to={'/' + params.url}></Navigate>;
};

export default Redirector;
