import * as React from 'react';
import DataApi, { TDriveType } from '../../api/DataApi';
import AgreementBox from '../agreement-box/AgreementBox';
import KeyValuePair from '../key-value-pair/KeyValuePair';

import style from './ChangeVignetPageSummary.module.css';

type TChangeVignetPageSummary = {
  bsn: string;
  initials: string;
  prefix: string;
  lastName: string;

  street: string;
  houseNumber: string;
  addition: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  email: string;

  vignetNumber: string;
  objectName: string;
  driveType: string;

  mooring: string;
  vignetType: string;

  totalCost: string;

  paymentType: string;

  setHasAgreedUserAgreement: Function;
  setHasAgreedDetailsCorrect: Function;
  setPageError: Function;

  defaultHasAgreedUserAgreement: boolean;
  defaultHasAgreedDetailsCorrect: boolean;

  validity: boolean;
};

const ChangeVignetPageSummary = (props: TChangeVignetPageSummary) => {
  const [hasAgreedUserAgreement, setHasAgreedUserAgreement] = React.useState<boolean>(
    props.defaultHasAgreedUserAgreement || false,
  );
  const [hasAgreedDetailsCorrect, setHasAgreedDetailsCorrect] = React.useState<boolean>(
    props.defaultHasAgreedDetailsCorrect || false,
  );

  return (
    <React.Fragment>
      <div className={style.pageHeader}>Controleer uw gegevens</div>
      <div className={style.text}>
        Hieronder vindt u een overzicht van uw gegevens. Controleer de gegevens goed. U kunt nog
        wijzigingen doorvoeren als er iets niet klopt.
      </div>
      <div className={style.pageSubHeader}>Uw persoonlijke gegevens</div>
      <KeyValuePair horizontal={true} label={'BSN'} value={props.bsn}></KeyValuePair>
      <KeyValuePair
        horizontal={true}
        label={'Uw voorletters'}
        value={props.initials}
      ></KeyValuePair>
      <KeyValuePair horizontal={true} label={'Tussenvoegsel'} value={props.prefix}></KeyValuePair>
      <KeyValuePair horizontal={true} label={'Achternaam'} value={props.lastName}></KeyValuePair>
      <div className={style.pageSubHeader}>Uw contactgegevens</div>
      <KeyValuePair horizontal={true} label={'Straat'} value={props.street}></KeyValuePair>
      <KeyValuePair horizontal={true} label={'Huisnummer'} value={props.houseNumber}></KeyValuePair>
      <KeyValuePair horizontal={true} label={'Toevoeging'} value={props.addition}></KeyValuePair>
      <KeyValuePair horizontal={true} label={'Postcode'} value={props.zipCode}></KeyValuePair>
      <KeyValuePair horizontal={true} label={'Woonplaats'} value={props.city}></KeyValuePair>
      <KeyValuePair
        horizontal={true}
        label={'Telefoonnummer'}
        value={props.phoneNumber}
      ></KeyValuePair>
      <KeyValuePair horizontal={true} label={'E-mailadres'} value={props.email}></KeyValuePair>
      <div className={style.pageSubHeader}>Gegevens van uw boot</div>
      <KeyValuePair
        horizontal={true}
        label={'Vignetnummer'}
        value={props.vignetNumber}
      ></KeyValuePair>
      <KeyValuePair horizontal={true} label={'Naam boot'} value={props.objectName}></KeyValuePair>
      <KeyValuePair horizontal={true} label={'Soort motor'} value={props.driveType}></KeyValuePair>
      <KeyValuePair
        horizontal={true}
        label={'Ligt uw boot in een jachthaven, gepacht water of buiten Amsterdam?'}
        value={props.mooring && parseInt(props.mooring) > 0 ? 'Ja' : 'Nee'}
      ></KeyValuePair>
      <KeyValuePair horizontal={true} label={'Type vignet'} value={props.vignetType}></KeyValuePair>
      <KeyValuePair
        horizontal={true}
        label={'Totaal kosten'}
        value={props.totalCost}
      ></KeyValuePair>
      <div className={style.pageSubHeader}>Betaalmethode</div>
      <KeyValuePair
        horizontal={true}
        label={'Betaalmethode'}
        value={props.paymentType}
      ></KeyValuePair>
      <AgreementBox
        defaultChecked={hasAgreedUserAgreement}
        validity={props.validity || hasAgreedUserAgreement}
        onChange={(checked: boolean) => {
          props.setHasAgreedUserAgreement(checked);
          setHasAgreedUserAgreement(checked);
        }}
        text={
          'Ik heb alle gegevens correct en volledig ingevuld. Ik begrijp dat een aanvraag via internet dezelfde juridische status heeft als een aanvraag met een geschreven geldige handtekening.'
        }
      ></AgreementBox>
      <AgreementBox
        defaultChecked={hasAgreedDetailsCorrect}
        validity={props.validity || hasAgreedDetailsCorrect}
        onChange={(checked: boolean) => {
          props.setHasAgreedDetailsCorrect(checked);
          setHasAgreedDetailsCorrect(checked);
        }}
        text={
          'Ik ben op de hoogte van de regels die op het water van Amsterdam gelden, zoals ze staan beschreven in de Binnenhavengeldverordening Pleziervaart en de Verordening op het binnenwater (te vinden op '
        }
      >
        <a href="https://amsterdam.nl/varen">amsterdam.nl/varen</a>
        <span>). Bij het wijzigen of opzeggen van mijn vignet ga ik met deze regels akkoord.</span>
      </AgreementBox>
    </React.Fragment>
  );
};

export default ChangeVignetPageSummary;
export type { TChangeVignetPageSummary };
