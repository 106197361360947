import Config from '../config/Config';
import Util from '../util/Util';

type TField = {
  value: string;
  isEditable: boolean;
  required: boolean;
};

type TOwner = {
  initials: TField;
  prefix: TField;
  lastname: TField;
  mobilephone: TField;
  bsn: TField;
  address: TField;
  number: TField;
  postcode: TField;
  additions: TField;
  city: TField;
  account_holder: TField;
  account_number: TField;
  bhg_account: boolean;
};

type TObject = {
  name: TField;
  length: TField;
  width: TField;
  drive_type: TField;
  vignet_type: TField;
  start_date: TField;
  amount: TField;
};

type TIssuer = {
  id: string;
  name: string;
};

type TError = {
  status: number;
  statusText: string;
  message: string;
};

const login = (email: string, pincode: string, onSuccess: Function, onFailure: Function) => {
  fetch(Config.api.baseUrl + '/api/strike/login', {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ email: email, pincode: pincode }),
  })
    .then(async (response: Response) => {
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw {
          status: response.status,
          statusText: response.statusText,
          message: json.message,
        };
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(json.access_token);
    })
    .catch((error: TError) => {
      console.log(error);
      onFailure(error);
    });
};

const getOwner = (onSuccess: Function, onFailure: Function) => {
  const accessToken = window.localStorage.getItem('access_token');
  fetch(Config.api.baseUrl + '/api/strike/owner', {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
    }),
  })
    .then(async (response: Response) => {
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw new Error(json.message);
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(json);
    })
    .catch((error: Error) => {
      onFailure(error);
    });
};

const getObject = (onSuccess: Function, onFailure: Function) => {
  const accessToken = window.localStorage.getItem('access_token');
  fetch(Config.api.baseUrl + '/api/strike/object', {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
    }),
  })
    .then(async (response: Response) => {
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw new Error(json.message);
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(json);
    })
    .catch((error: Error) => {
      onFailure(error);
    });
};

const requestNewPincode = (phone: string, onSuccess: Function, onFailure: Function) => {
  const accessToken = window.localStorage.getItem('access_token');
  fetch(Config.api.baseUrl + '/api/strike/pincode', {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
    }),
    body: JSON.stringify({ phone: phone }),
  })
    .then(async (response: Response) => {
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw new Error(json.message);
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(json);
    })
    .catch((error: Error) => {
      onFailure(error);
    });
};

const performPayment = (
  issuer: string,
  payment_method_id: string,
  data: any,
  onSuccess: Function,
  onFailure: Function,
) => {
  const accessToken = window.localStorage.getItem('access_token');
  console.log(data);
  const urlEncodeData: string = window.btoa(window.encodeURIComponent(JSON.stringify(data)));
  let baseUrl = window.location.href.trim();
  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  }
  fetch(Config.api.baseUrl + '/api/strike/payment', {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
    }),
    body: JSON.stringify({
      issuer: issuer,
      payment_method_id: payment_method_id,
      return_url_success: Util.getPageUrl() + '/' + urlEncodeData + '/done',
      return_url_cancel: Util.getPageUrl() + '/' + urlEncodeData + '/cancel',
      return_url_error: Util.getPageUrl() + '/' + urlEncodeData + '/error',
      return_url_reject: Util.getPageUrl() + '/' + urlEncodeData + '/reject',
    }),
  })
    .then(async (response: Response) => {
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw new Error(json.message);
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(json);
    })
    .catch((error: Error) => {
      onFailure(error);
    });
};

const changePassword = (password: string, onSuccess: Function, onFailure: Function) => {
  const accessToken = window.localStorage.getItem('access_token');
  fetch(Config.api.baseUrl + '/api/strike/password', {
    method: 'PATCH',
    mode: 'cors',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
    }),
    body: JSON.stringify({ password: password }),
  })
    .then(async (response: Response) => {
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw new Error(json.message);
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(json);
    })
    .catch((error: Error) => {
      onFailure(error);
    });
};

const getPaymentMethods = (onSuccess: Function, onFailure: Function) => {
  const accessToken = window.localStorage.getItem('access_token');
  fetch(Config.api.baseUrl + '/api/strike/payment/methods', {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
    }),
  })
    .then(async (response: Response) => {
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw new Error(json.message);
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(json);
    })
    .catch((error: Error) => {
      onFailure(error);
    });
};

const changeOwner = (
  account_holder: string,
  account_number: string,
  onSuccess: Function,
  onFailure: Function,
) => {
  const accessToken = window.localStorage.getItem('access_token');
  fetch(Config.api.baseUrl + '/api/strike/owner', {
    method: 'PATCH',
    mode: 'cors',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
    }),
    body: JSON.stringify({
      account_holder: account_holder,
      account_number: account_number,
    }),
  })
    .then(async (response: Response) => {
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw new Error(json.message);
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(json);
    })
    .catch((error: Error) => {
      onFailure(error);
    });
};

const getSummary = (onSuccess: Function, onFailure: Function) => {
  const accessToken = window.localStorage.getItem('access_token');
  fetch(Config.api.baseUrl + '/api/strike/summary/get', {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
    }),
  })
    .then(async (response: Response) => {
      if (response.status === 204) {
        return null;
      }
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw {
          status: response.status,
          statusText: response.statusText,
          message: json.message,
        };
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(json);
    })
    .catch((error: Error) => {
      onFailure(error);
    });
};

const getIssuers = (onSuccess: Function, onFailure: Function) => {
  const accessToken = window.localStorage.getItem('access_token');
  fetch(Config.api.baseUrl + '/api/strike/payment/issuers', {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: 'Bearer ' + accessToken,
    }),
  })
    .then(async (response: Response) => {
      const json = await response.json();
      if (response.status < 200 || response.status >= 300) {
        throw new Error(json.message);
      }
      return json;
    })
    .then((json: any) => {
      onSuccess(Object.values(json));
    })
    .catch((error: Error) => {
      onFailure(error);
    });
};

export default {
  login,
  getOwner,
  getObject,
  requestNewPincode: requestNewPincode,
  performPayment: performPayment,
  changePassword: changePassword,
  changeOwner: changeOwner,
  getPaymentMethods: getPaymentMethods,
  getSummary: getSummary,
  getIssuers: getIssuers,
};
export type { TOwner, TObject, TIssuer, TError };
