import * as React from 'react';
import { useNavigate } from 'react-router';
import DataApi from '../../api/DataApi';
import CenteredForm from '../../components/centered-form/CenteredForm';
import DownloadSummaryButton from '../../components/download-summary/DownloadSummary';
import PageHeader from '../../components/page-header/PageHeader';
import TitleLineComponent from '../../components/page-layout/TitleLineComponent';
import FormStepper from '../form-stepper/FormStepper';

import style from './ChangeVignetDonePage.module.css';

const ChangeVignetDonePage = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    console.log('getting summary');
    DataApi.getExtendSummary(
      (json: any) => {
        window.open(json.summary_link);
      },
      (error: Error) => {
        console.error(error);
      },
    );
  };

  return (
    <React.Fragment>
      <PageHeader className={style.pageHeader}></PageHeader>
      <div id={style.container}>
        <CenteredForm
          onPrevious={() => {
            navigate('/vignet-overview');
          }}
          onPreviousText="< Terug naar vignetoverzicht"
        >
          <TitleLineComponent text={'Vignet wijzigen'} />
          <div id={style.header}>Bedankt</div>
          <div id={style.subHeader}>
            Hartelijk dank voor uw wijziging.{' '}
            <div id={style.subHeader}>
              Wij hebben deze ontvangen op{' '}
              {new Date().toLocaleDateString('nl-NL', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
              .
            </div>
          </div>
          <div>U krijgt binnen 4 werkdagen via e-mail een bevestiging van ons.</div>
          <br></br>
          <div className={style.pageSubHeader}>Samenvatting</div>
          <DownloadSummaryButton onClick={handleOnClick}></DownloadSummaryButton>
          <div className={style.pageSubHeader}>Vragen?</div>
          <div>
            Bel het telefoonnummer 14 020, maandag tot en met vrijdag van 08.00 tot 18.00 uur. Of
            gebruik het contactformulier op de website.
            <br></br>
            Houd uw vignetnummer bij de hand.
          </div>
        </CenteredForm>
      </div>
    </React.Fragment>
  );
};

export default ChangeVignetDonePage;
