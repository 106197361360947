const getPageUrl = (raw?: boolean) => {
  let url = window.location.href;
  url = url.replace('/cancel', '');
  url = url.replace('/reject', '');
  url = url.replace('/done', '');
  url = url.replace('/error', '');
  if (!raw) {
    url = url.replace('//', '++');
  }
  const parts = url.split('/', 3);
  if (!raw) {
    url = parts[0] + '/' + parts[1];
  }
  if (!raw) {
    url = url.replace('++', '//');
  }
  if (url.endsWith('/')) {
    return url.substring(0, url.length - 1);
  }
  return url;
};

const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Util = {
  getPageUrl: getPageUrl,
  capitalize: capitalize,
};

export default Util;
