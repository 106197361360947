import * as React from 'react';

import style from './DownloadSummaryButton.module.css';

type TDownloadSummaryButton = {
  onClick: Function;
};

const DownloadSummaryButton = (props: TDownloadSummaryButton) => {
  return (
    <div
      id={style.container}
      onClick={() => {
        props.onClick();
      }}
    >
      Downloaden Samenvatting
    </div>
  );
};

export default DownloadSummaryButton;
