import * as React from 'react';

import style from './EditHeader.module.css';

type TEditHeader = {
  text: string;
  onEdit: Function;
  hideEdit?: boolean;
};

const EditHeader = (props: TEditHeader) => {
  return (
    <div id={style.container}>
      <div id={style.text}>{props.text}</div>
      {!props.hideEdit ? (
        <div
          id={style.edit}
          onClick={() => {
            props.onEdit();
          }}
        >
          Wijzig
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default EditHeader;
