import * as React from 'react';
import ButtonNext from '../../components/button-next/ButtonNext';
import ButtonPrevious from '../../components/button-previous/ButtonPrevious';

import style from './FormButtonBar.module.css';

type TFormButtonBar = {
  onPrevious?: Function;
  onNext?: Function;
  locked?: boolean;
  onPreviousText?: string;
  onNextText?: string;
};

const FormButtonBar = (props: TFormButtonBar) => {
  const handleOnNext = (e: any) => {
    if (props.locked) {
      return;
    }
    props.onNext?.(e);
  };

  const handleOnPrevious = (e: any) => {
    if (props.locked) {
      return;
    }
    props.onPrevious?.(e);
  };

  const previousButton = props.onPrevious ? (
    <ButtonPrevious onClick={handleOnPrevious}>
      {props.onPreviousText ? props.onPreviousText : '< Vorige'}
    </ButtonPrevious>
  ) : (
    <ButtonPrevious onClick={handleOnPrevious}>
      {props.onPreviousText ? props.onPreviousText : ''}
    </ButtonPrevious>
  );
  const nextButton = props.onNext ? (
    <ButtonNext onClick={handleOnNext} arrowMode={true}>
      {props.locked ? 'Bezig..' : props.onNextText ? props.onNextText : 'Volgende'}
    </ButtonNext>
  ) : (
    ''
  );

  return (
    <div id={style.container}>
      {previousButton}
      {nextButton}
    </div>
  );
};

export default FormButtonBar;
