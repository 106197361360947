import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import DataApi, { TPaymentType, TProductType } from '../../api/DataApi';
import VignetApi from '../../api/VignetApi';
import AgreementBox from '../../components/agreement-box/AgreementBox';
import CenteredForm from '../../components/centered-form/CenteredForm';
import EditHeader from '../../components/edit-header/EditHeader';
import KeyValuePair from '../../components/key-value-pair/KeyValuePair';
import LabeledInputField from '../../components/labeled-input-field/LabeledInputField';
import PageHeader from '../../components/page-header/PageHeader';
import FormStepper, { TStep } from '../form-stepper/FormStepper';
import TitleLineComponent from '../../components/page-layout/TitleLineComponent';

import style from './MooringPage.module.css';
import PaymentTypeSelector from '../../components/payment-type-selector/PaymentTypeSelector';
import AlertBox, { ESeverity } from '../../components/alert-box/AlertBox';

type TFormDataEntry = {
  value: string;
  validator?: Function;
};

type TFormData = {
  [key: string]: TFormDataEntry;
};

const TransitPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [action, setAction] = React.useState<number>(0);

  const [mooringType, setMooringType] = React.useState<string | undefined>(undefined);
  const [paymentType, setPaymentType] = React.useState<string | undefined>(undefined);

  const [formData, setFormData] = React.useState<TFormData>({});

  const [cardServiceCode, setCardServiceCode] = React.useState<string | undefined>();

  const [progress, setProgress] = React.useState<number>(0);

  const [pageError, setPageError] = React.useState<string | undefined>(undefined);

  const [valid, setValid] = React.useState<boolean>(true);

  const [truthfulAgreement, setTruthfulAgreement] = React.useState<boolean>(false);
  const [endUserAgreement, setEndUserAgreement] = React.useState<boolean>(false);

  const [staticMode, setStaticMode] = React.useState<boolean>(false);

  const getVignetTypeObject = () => {
    return vignetTypes?.find((entry: TProductType) => {
      return entry.ProductID === mooringType;
    });
  };

  const [mooringTypes, setMooringTypes] = React.useState<TPaymentType[] | undefined>(() => {
    DataApi.getMooringPaymentTypes(
      (mooringTypes: TPaymentType[]) => {
        setMooringTypes(mooringTypes);
      },
      (error: Error) => {
        console.log(error);
        setPageError(error.message);
      },
    );
    return undefined;
  });

  const [paymentTypes, setPaymentTypes] = React.useState<TPaymentType[] | undefined>(() => {
    DataApi.getMooringPaymentTypes(
      (paymentTypes: TPaymentType[]) => {
        setPaymentTypes(paymentTypes);
      },
      (error: Error) => {
        console.log(error);
        setPageError(error.message);
      },
    );
    return undefined;
  });

  const [vignetTypes, setVignetTypes] = React.useState<TProductType[] | undefined>(() => {
    DataApi.getMooringTypes(
      (mooringTypes: TProductType[]) => {
        setVignetTypes(mooringTypes);
      },
      (error: Error) => {
        console.error(error);
        setPageError(error.message);
      },
    );
    return undefined;
  });

  if (!mooringTypes) {
    return <div>Loading payment methods.. {pageError}</div>;
  }

  const required = (value: string) => {
    setAction(action + 1);
    return value && value.trim().length > 0;
  };

  const isValidPhoneNumber = () => {
    setAction(action + 1);
    return /^\+??\d{3}?[-\s\.]?\d{3}[-\s\.]?\d{4,9}$/im.test(formData.phoneNumber.value);
  };

  const isSamePhoneNumber = () => {
    setAction(action + 1);
    if (formData.repeatPhoneNumber.value.length <= 0) {
      return false;
    }
    if (formData.phoneNumber.value === formData.repeatPhoneNumber.value) {
      setValid(true);
    }
    return formData.phoneNumber.value === formData.repeatPhoneNumber.value;
  };

  const isValidEmail = () => {
    setAction(action + 1);
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.value);
  };

  const isSameEmail = () => {
    setAction(action + 1);
    if (formData.repeatEmail.value.length <= 0) {
      return false;
    }
    if (formData.email.value === formData.repeatEmail.value) {
      setValid(true);
    }
    return formData.email.value === formData.repeatEmail.value;
  };

  if (params.data && !formData.lastName) {
    const data = JSON.parse(window.atob(params.data));
    const formData: TFormData = {};
    for (const [key, value] of Object.entries(data)) {
      if (key === 'productID') {
        setMooringType(value as string);
        continue;
      }
      if (key === 'paymentTypeID') {
        setPaymentType(value as string);
        continue;
      }
      if (key === 'cardServiceCode') {
        setCardServiceCode(value as string);
        continue;
      }
      if (key === 'vignetID') {
        setPaymentType(value as string);
        continue;
      }
      if (key === 'email') {
        formData.repeatEmail = { value: value as string, validator: isSameEmail };
      }
      if (key === 'phoneNumber') {
        formData.repeatPhoneNumber = { value: value as string, validator: isSamePhoneNumber };
      }
      formData[key] = { value: value as string };
    }
    setFormData(formData);
    setProgress(4);
    setStaticMode(true);
    console.log('Done parsing:', formData);
  }

  const steps: TStep[] = [
    { text: 'Nummer vignet invoeren' },
    { text: 'Kies 6 of 24 uur afmeren' },
    { text: 'Contactgegevens' },
    { text: 'Betalen' },
    { text: 'Samenvatting' },
    { text: 'Bedankt' },
  ];

  const handleOnNext = () => {
    if (progress === 0) {
      if (!formData.vignetNumber.value) {
        console.error('no vignetnumber');
        setValid(false);
        return;
      }
      if (formData.vignetNumber.value !== formData.repeatVignetNumber.value) {
        console.error('not the same');
        setValid(false);
        return;
      }
    }

    if (progress === 1) {
      if (!mooringType) {
        setPageError('Kies één van bovenstaande opties!');
        setValid(false);
        return;
      }
    }

    setValid(true);
    if (progress === 2) {
      if (!validateFormData()) {
        setPageError('U heeft één of meerdere van de velden niet correct ingevuld');
        setValid(false);
        return;
      }
    }

    if (progress === 3) {
      if (!paymentType) {
        setPageError('Kies één van bovenstaande opties!');
        setValid(false);
        return;
      }

      if (paymentType.split(':')[0].toLocaleLowerCase() !== 'ideal' && !cardServiceCode) {
        setPageError('Kies voor Visa of Mastercard!');
        setValid(false);
        return;
      }
    }

    if (progress === 4) {
      if (!truthfulAgreement || !endUserAgreement) {
        setPageError('Ga akkoord met het bovenstaande om verder te gaan.');
        setValid(false);
        return;
      } else {
        sendMooringRequest();
        return;
      }
    }

    setPageError(undefined);
    setProgress(progress + 1);
  };

  const handleOnValidateVignet = () => {
    if (!formData.vignetNumber.value) {
      setPageError('Vul uw vignetnummer in.');
      console.error('No vignet');
      return;
    }

    if (!formData.repeatVignetNumber.value) {
      setPageError('Vul nogmaals uw vignetnummer in.');
      console.error('No repeat vignet');
      return;
    }

    if (formData.vignetNumber.value !== formData.repeatVignetNumber.value) {
      setPageError('Uw vignetnummers komen niet overeen.');
      console.error('Mismatched vignet numbers.');
      return;
    }

    VignetApi.validateMooringVignet(
      formData.vignetNumber.value,
      () => {
        setPageError(undefined);
        setProgress(progress + 1);
      },
      (error: Error) => {
        console.error('invalid vignet');
        setPageError(error.message);
      },
    );
  };

  const handleOnPrevious = () => {
    setPageError(undefined);
    if (progress < 0) {
      navigate('/');
    }
    setProgress(progress - 1);
  };

  const validateFormData = () => {
    let valid = true;
    for (const [key, value] of Object.entries(formData)) {
      const entry: TFormDataEntry = value as unknown as TFormDataEntry;
      if (!entry.validator) {
        continue;
      }
      if (!entry.validator()) {
        valid = false;
        console.log('failed:' + key);
      }
    }
    return valid;
  };

  const sendMooringRequest = () => {
    VignetApi.sendMooringRequest(
      formData.firstName.value,
      formData?.prefix?.value || '',
      formData.lastName.value,
      formData.vignetNumber.value,
      formData.phoneNumber.value,
      formData.email.value,
      paymentType || '',
      cardServiceCode || '',
      mooringType || '',
      (json: any) => {
        window.localStorage.setItem('access_token', json.access_token);
        window.location = json.redirect_url;
      },
      (error: Error) => {
        console.error('failed to request mooring');
        setPageError(error.message);
      },
    );
  };

  const handleOnChangeVignetType = (e: any) => {
    setMooringType(e.target.value);
  };

  const handleOnChangePaymentType = (e: any) => {
    setPaymentType(e.target.value);
  };

  const handleOnChangeServiceCode = (e: any) => {
    setCardServiceCode(e.target.value);
  };

  const handleOnClick = () => {
    DataApi.getMooringSummary(
      (json: any) => {
        window.open(json.summary_link);
      },
      (error: Error) => {
        console.error(error);
      },
    );
  };

  const getPaymentStatusDOM = () => {
    switch (params.status) {
      case 'cancel':
        return (
          <AlertBox
            severity={ESeverity.HIGH}
            header={'De betaling is mislukt.'}
            text={'Probeer het opnieuw of kies een andere manier om te betalen.'}
          ></AlertBox>
        );
      case 'reject':
        return (
          <AlertBox
            severity={ESeverity.HIGH}
            header={'De betaling is mislukt.'}
            text={'Probeer het opnieuw of kies een andere manier om te betalen.'}
          ></AlertBox>
        );
      case 'success':
        return (
          <AlertBox severity={ESeverity.LOW} header={'De betaling is gelukt.'} text={''}></AlertBox>
        ); // Unused.
      case 'error':
        return (
          <AlertBox
            severity={ESeverity.HIGH}
            header={'De betaling is mislukt.'}
            text={'Probeer het opnieuw of kies een andere manier om te betalen.'}
          ></AlertBox>
        );
    }
    return <React.Fragment></React.Fragment>;
  };

  // console.log(mooringType);
  if (progress === 4) {
    const vignetTypeObject: TProductType | undefined = getVignetTypeObject();
    return (
      <React.Fragment>
        <PageHeader className={style.pageHeader}></PageHeader>
        <div id={style.container}>
          <FormStepper steps={steps} currentStep={4}></FormStepper>
          <CenteredForm
            onPrevious={staticMode ? undefined : handleOnPrevious}
            onNext={() => {
              handleOnNext();
            }}
          >
            <TitleLineComponent text={'6 of 24 uur afmeren kopen'} />
            <div id={style.header}>Samenvatting</div>
            <div id={style.subHeader}>Hieronder staan uw gegevens voor afmeren.</div>
            <KeyValuePair
              horizontal={true}
              label={'Duur afmeren'}
              value={vignetTypeObject?.Name || ''}
            ></KeyValuePair>
            <EditHeader
              text={'Contactgegevens'}
              onEdit={() => {
                setProgress(2);
              }}
              hideEdit={staticMode}
            ></EditHeader>
            <KeyValuePair
              horizontal={true}
              label={'Voorletters'}
              value={formData.firstName.value}
            ></KeyValuePair>
            <KeyValuePair
              horizontal={true}
              label={'Tussenvoegsel'}
              value={formData?.prefix?.value || ''}
            ></KeyValuePair>
            <KeyValuePair
              horizontal={true}
              label={'Achternaam'}
              value={formData.lastName.value}
            ></KeyValuePair>
            <KeyValuePair
              horizontal={true}
              label={'Telefoonnummer'}
              value={formData.phoneNumber.value}
            ></KeyValuePair>
            <KeyValuePair
              horizontal={true}
              label={'E-mailadres'}
              value={formData.email.value}
            ></KeyValuePair>
            <EditHeader
              text={'Betaling'}
              onEdit={() => {
                setProgress(3);
              }}
              hideEdit={staticMode}
            ></EditHeader>
            <KeyValuePair
              horizontal={true}
              label={'Betaalwijze'}
              value={paymentType?.split(':')[0] || ''}
            ></KeyValuePair>
            <br></br>
            <KeyValuePair
              horizontal={true}
              label={'Kosten'}
              value={vignetTypeObject?.Price || ''}
            ></KeyValuePair>
            <KeyValuePair
              horizontal={true}
              label={'Totaalbedrag'}
              value={vignetTypeObject?.Price || ''}
            ></KeyValuePair>
            <AgreementBox
              defaultChecked={truthfulAgreement}
              validity={valid || truthfulAgreement}
              text={
                'Ik heb alle gegevens correct en volledig ingevuld. Ik begrijp dat een aanvraag via internet dezelfde juridische status heeft als een aanvraag met een geschreven geldige handtekening.'
              }
              onChange={(checked: boolean) => {
                setTruthfulAgreement(checked);
              }}
            ></AgreementBox>
            <AgreementBox
              defaultChecked={endUserAgreement}
              validity={valid || endUserAgreement}
              text={
                'Ik ben op de hoogte van de regels die op het water van Amsterdam gelden, zoals ze staan beschreven in de Binnenhavengeldverordening Pleziervaart en de Verordening op het binnenwater 2010. Kijk op '
              }
              onChange={(checked: boolean) => {
                setEndUserAgreement(checked);
              }}
            >
              <a href="https://www.amsterdam.nl/parkeren-verkeer/varen-amsterdam/re-geling-verordeningen-water/">
                "https://www.amsterdam.nl/parkeren-verkeer/varen-amsterdam/re-geling-verordeningen-water/"{' '}
              </a>
              <div>voor meer informatie.</div>
            </AgreementBox>
            <div id={style.pageError}>{pageError}</div>
            {staticMode ? getPaymentStatusDOM() : <React.Fragment></React.Fragment>}
          </CenteredForm>
        </div>
      </React.Fragment>
    );
  }

  if (progress === 3) {
    const validityStyling = !valid && !paymentType ? style.invalid : style.valid;
    return (
      <React.Fragment>
        <PageHeader className={style.pageHeader}></PageHeader>
        <div id={style.container}>
          <FormStepper steps={steps} currentStep={3}></FormStepper>
          <CenteredForm
            onPrevious={() => {
              handleOnPrevious();
            }}
            onNext={() => {
              handleOnNext();
            }}
          >
            <TitleLineComponent text={'6 of 24 uur afmeren kopen'} />

            <div id={style.header}>Betalen</div>
            <div id={style.subHeader}>Kies betaalmethode.</div>
            <PaymentTypeSelector
              serviceCode={cardServiceCode}
              paymentType={paymentType}
              paymentTypes={paymentTypes}
              onChangePaymentType={handleOnChangePaymentType}
              onChangeServiceCode={handleOnChangeServiceCode}
              validityStyling={validityStyling}
            ></PaymentTypeSelector>
            <div id={style.pageError}>{pageError}</div>
          </CenteredForm>
        </div>
      </React.Fragment>
    );
  }

  if (progress === 2) {
    return (
      <React.Fragment>
        <PageHeader className={style.pageHeader}></PageHeader>
        <div id={style.container}>
          <FormStepper steps={steps} currentStep={2}></FormStepper>
          <CenteredForm
            onPrevious={() => {
              handleOnPrevious();
            }}
            onNext={() => {
              handleOnNext();
            }}
          >
            <TitleLineComponent text={'6 of 24 uur afmeren kopen'} />
            <div id={style.header}>Contactgegevens</div>
            <div id={style.subHeader}>
              Vul hier uw contactgegevens in, zodat u een bevestiging ontvangt.
            </div>
            <LabeledInputField
              text={'Voorletters'}
              name={'firstName'}
              formData={formData}
              validator={required}
            ></LabeledInputField>
            <LabeledInputField
              text={'Tussenvoegsel'}
              name={'prefix'}
              formData={formData}
            ></LabeledInputField>
            <LabeledInputField
              text={'Achternaam'}
              name={'lastName'}
              formData={formData}
              validator={required}
            ></LabeledInputField>
            <LabeledInputField
              text={'Telefoonnummer'}
              name={'phoneNumber'}
              formData={formData}
              validator={isValidPhoneNumber}
            ></LabeledInputField>
            <LabeledInputField
              text={'Herhaal telefoonnummer'}
              name={'repeatPhoneNumber'}
              formData={formData}
              validator={isSamePhoneNumber}
            ></LabeledInputField>
            <LabeledInputField
              text={'E-mailadres'}
              name={'email'}
              formData={formData}
              validator={isValidEmail}
            ></LabeledInputField>
            <LabeledInputField
              text={'Herhaal e-mailadres'}
              name={'repeatEmail'}
              formData={formData}
              validator={isSameEmail}
            ></LabeledInputField>
            <h6>Velden met * zijn verplicht.</h6>
            <div id={style.pageError}>{pageError}</div>
          </CenteredForm>
        </div>
      </React.Fragment>
    );
  }

  const getVignetTypesDOM = () => {
    if (!vignetTypes) {
      return <div>Loading vignet types..</div>;
    }
    return vignetTypes.map((vignetType: TProductType, index: number) => {
      return (
        <div key={'vignet_type_option_' + index} className={style.selectTypeContainer}>
          <input
            defaultChecked={vignetType.ProductID === mooringType}
            type={'radio'}
            name={'vignetType'}
            value={vignetType.ProductID}
            onChange={handleOnChangeVignetType}
          ></input>
          <div>{vignetType.Description}</div>
        </div>
      );
    });
  };

  if (progress === 1) {
    return (
      <React.Fragment>
        <PageHeader className={style.pageHeader}></PageHeader>
        <div id={style.container}>
          <FormStepper steps={steps} currentStep={1}></FormStepper>
          <CenteredForm
            onPrevious={() => {
              handleOnPrevious();
            }}
            onNext={() => {
              handleOnNext();
            }}
          >
            <TitleLineComponent text={'6 of 24 uur afmeren kopen'} />
            <div id={style.header}>Maak uw keuze</div>
            <React.Fragment>{getVignetTypesDOM()}</React.Fragment>
            <div id={style.pageError}>{pageError}</div>
            <div></div>
          </CenteredForm>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PageHeader className={style.pageHeader}></PageHeader>
      <div id={style.container}>
        <FormStepper steps={steps} currentStep={0}></FormStepper>
        <CenteredForm
          onNext={() => {
            handleOnValidateVignet();
          }}
        >
          <TitleLineComponent text={'6 of 24 uur afmeren kopen'} />

          <div id={style.header}>Nummer van uw vignet</div>
          <div id={style.subHeader}>
            U kunt hier '6 uur afmeren' of '24 uur afmeren' kopen voor een boot korter dan 10 meter.
          </div>
          <LabeledInputField
            text={'Nummer van uw vignet'}
            name={'vignetNumber'}
            formData={formData}
          ></LabeledInputField>
          <LabeledInputField
            text={'Herhaal nummer van uw vignet'}
            name={'repeatVignetNumber'}
            formData={formData}
          ></LabeledInputField>
          <div id={style.pageError}>{pageError}</div>
          <img id={style.exampleVignet} src="../vignetnummber.png"></img>
          <div id={style.disclaimer}>
            De gemeente Amsterdam gaat zorgvuldig en veilig met uw persoonsgegevens om. Wij houden
            ons daarbij aan de privacywetten en -regels. Dat betekent bijvoorbeeld dat wij uw
            gegevens alleen voor deze aanvraag gebruiken en niet voor iets anders. Meer informatie
            vindt u in de{' '}
            <a href="https://www.amsterdam.nl/privacy/specifieke/privacyverklaring-parkeren-verkeer-bouw/verkeersmanagement-gracht-privacy/">
              privacyverklaring Binnenhavengeld
            </a>
            .
          </div>
        </CenteredForm>
      </div>
    </React.Fragment>
  );
};

export default TransitPage;
