import * as React from 'react';

import style from './UploadField.module.css';

type TFile = {
  name: string;
  data: string;
  native?: any;
};

type TFileList = {
  files: TFile[];
  removeFile?: Function;
  fileElement?: any;
};

type TUploadField = {
  onChange: Function;
  validity: boolean;
  text?: string;
  files: TFile[];
  removeFile?: Function;
  singleFileText?: boolean;
  accept?: string;
};

const FileList = (props: TFileList) => {
  return (
    <div id={style.fileListContainer}>
      {props.files.map((file: TFile, index: number) => {
        return (
          <div key={'file_container_' + index}>
            <div className={style.file}>{file.name}</div>
            {props.removeFile ? (
              <div
                className={style.remove}
                onClick={() => {
                  if (props.fileElement) {
                    props.fileElement.value = null;
                  }
                  props.removeFile?.(index);
                }}
              >
                X Wissen
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        );
      })}
    </div>
  );
};

const UploadField = (props: TUploadField) => {
  const [fileElement, setFileElement] = React.useState();

  return (
    <React.Fragment>
      <div id={style.container} className={props.validity ? style.valid : style.invalid}>
        {props.text ? (
          <div>{props.text}</div>
        ) : props.singleFileText ? (
          <div>
            Sleep het bestand in dit vak of <div id={style.blue}>selecteer het bestand</div>
          </div>
        ) : (
          <div>
            Sleep de bestanden in dit vak of <div id={style.blue}>selecteer bestanden</div>
          </div>
        )}
        <input
          accept={props.accept}
          type={'file'}
          multiple={true}
          onChange={(e: any) => {
            setFileElement(e.target);
            props.onChange(e);
          }}
        ></input>
      </div>
      <FileList
        fileElement={fileElement}
        removeFile={props.removeFile}
        files={props.files}
      ></FileList>
    </React.Fragment>
  );
};

export default UploadField;
export type { TUploadField, TFileList, TFile };
