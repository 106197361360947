import process from "process";

// const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const development = process.env.NODE_ENV === 'development';

type TApi = {
  baseUrl: string;
};

type TGoogleMaps = {
  apiKey: string;
};

type TConfig = {
  version: string;
  development: boolean;
  api: TApi;
  googleMaps: TGoogleMaps;
  transitRedirectUrl: string;
};

const Config: TConfig = {
  version: "AUTO_GENERATED_VERSION",
  development: development,
  api: {
    baseUrl:
      process.env.REACT_APP_API_URL!
  },
  googleMaps: {
    apiKey:
      process.env.REACT_APP_MAP_API_KEY!
  },
  transitRedirectUrl: process.env.REACT_APP_TRANSIT_REDIRECT_URL!
};

export default Config;
