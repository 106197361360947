import * as React from 'react';

import style from './FormStepper.module.css';

type TStep = {
  text: string;
};

type TFormStepper = {
  steps: TStep[];
  currentStep: number;
};

const FormStepper = (props: TFormStepper) => {
  const checkMark = (
    <svg className={style.checkMark} viewBox="0 0 32 32" focusable="false">
      <path
        d="M12.216 27.016L0 14.168l2.91-2.77 9.346 9.837L29.129 4 32 6.8z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
  return (
    <div id={style.container}>
      {props.steps.map((step: TStep, index: number) => {
        const orbStyling =
          props.currentStep === index
            ? style.currentOrb
            : props.currentStep >= index
              ? style.orbBlue
              : style.orbGrey;
        const textStyling =
          props.currentStep === index
            ? style.currentText
            : props.currentStep >= index
              ? style.textBlue
              : style.textGrey;
        return (
          <div key={'stepper_container_' + index} className={style.step}>
            <div key={'stepper_orb_' + index} className={orbStyling}>
              {props.currentStep >= index ? checkMark : undefined}
            </div>
            <div key={'stepper_text_' + index} className={textStyling}>
              {step.text}
            </div>
            {index < props.steps.length - 1 ? (
              <div
                key={'stepper_line_' + index}
                className={props.currentStep - 1 >= index ? style.lineBlue : style.lineGrey}
              ></div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FormStepper;
export type { TStep };
