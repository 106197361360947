import * as React from 'react';
import KeyValuePair from '../key-value-pair/KeyValuePair';

import style from './ChangeVignetPageCalculations.module.css';

type TChangeVignetPageCalculations = {
  vignetType: string;
  objectSurface: string;
  totalCost: string;
};

const ChangeVignetPageCalculations = (props: TChangeVignetPageCalculations) => {
  return (
    <React.Fragment>
      <div className={style.pageSubHeader}>Vignet berekening</div>
      <KeyValuePair label={'Type vignet'} value={props.vignetType}></KeyValuePair>
      <KeyValuePair label={'Totaal oppervlakte m2'} value={props.objectSurface}></KeyValuePair>
      <KeyValuePair label={'Totaal kosten'} value={props.totalCost}></KeyValuePair>
    </React.Fragment>
  );
};

export default ChangeVignetPageCalculations;
