import { upload } from '@testing-library/user-event/dist/upload';
import * as React from 'react';
import DataApi from '../../api/DataApi';
import VignetApi, { TCheckVignet } from '../../api/VignetApi';
import AgreementBox from '../../components/agreement-box/AgreementBox';
import AlertBox, { ESeverity } from '../../components/alert-box/AlertBox';
import CenteredForm from '../../components/centered-form/CenteredForm';
import CountrySelectionField from '../../components/country-selection-field/CountrySelectionField';
import DownloadSummaryButton from '../../components/download-summary/DownloadSummary';
import EditHeader from '../../components/edit-header/EditHeader';
import KeyValuePair from '../../components/key-value-pair/KeyValuePair';
import LabeledInputField from '../../components/labeled-input-field/LabeledInputField';
import PageHeader from '../../components/page-header/PageHeader';
import TitleLineComponent from '../../components/page-layout/TitleLineComponent';
import UploadField, { TFile } from '../../components/upload-field/UploadField';
import FormStepper, { TStep } from '../form-stepper/FormStepper';

import style from './ActivateVignetPage.module.css';

enum EProgress {
  VALIDATION,
  PERSONAL_DETAILS,
  OBJECT_DETAILS,
  SUMMARY,
  THANK_YOU,
}

type TDriveType = {
  id: string;
  name: string;
};

type TObjectType = {
  id: string;
  name: string;
};

type TFormDataEntry = {
  value: string;
  validator?: Function;
};

type TFormData = {
  [key: string]: TFormDataEntry;
};

type TField = {
  value: string;
  isEditable: boolean;
};

const ActivateVignetPage = () => {
  const [vignetCode, setVignetCode] = React.useState<string>('');
  const [activationCode, setActivationCode] = React.useState<string>('');

  const [progress, setProgress] = React.useState<EProgress>(EProgress.VALIDATION);

  const [pageError, setPageError] = React.useState<string | undefined>(undefined);

  const [checkVignet, setCheckVignet] = React.useState<TCheckVignet | undefined>(undefined);

  const [formData, setFormData] = React.useState<TFormData>({});
  const [predefinedData, setPredefinedData] = React.useState<any>();

  const [country, setCountry] = React.useState<string>();
  const [countryName, setCountryName] = React.useState<string>();

  const [driveTypes, setDriveTypes] = React.useState<TDriveType[] | undefined>(undefined);
  const [objectTypes, setObjectTypes] = React.useState<TObjectType[] | undefined>(undefined);

  const [objectLength, setObjectLength] = React.useState<TField>({ value: '', isEditable: false });
  const [objectWidth, setObjectWidth] = React.useState<TField>({ value: '', isEditable: false });
  const [objectDriveType, setObjectDriveType] = React.useState<TField>({
    value: '',
    isEditable: false,
  });
  const [objectType, setObjectType] = React.useState<TField>({ value: '', isEditable: false });
  const [objectName, setObjectName] = React.useState<TField>({ value: '', isEditable: false });

  const [bsnRequired, setBsnRequired] = React.useState<boolean>(false);

  const [fileUploadBoat, setFileUploadBoat] = React.useState<TFile[]>([]);
  const [fileUploadVignet, setFileUploadVignet] = React.useState<TFile[]>([]);

  const [fileCount, setFileCount] = React.useState<number>(0);
  const [uploadRequired, setUploadRequired] = React.useState<boolean>(false);

  const [action, setAction] = React.useState<number>(0);

  const [isValid, setValid] = React.useState<boolean>(false);

  const [hasAgreedTruthfully, setHasAgreedTruthfully] = React.useState<boolean>(false);
  const [hasAgreedEndUser, setHasAgreedEndUser] = React.useState<boolean>(false);

  const [working, setWorking] = React.useState<boolean>(false);

  const steps: TStep[] = [
    { text: 'Nummer vignet invoeren' },
    { text: 'Uw gegevens' },
    { text: 'Gegevens van uw boot' },
    { text: 'Samenvatting van uw aanvraag' },
    { text: 'Bedankt' },
  ];

  const validateFormData = () => {
    let valid = true;

    for (const [key, value] of Object.entries(formData)) {
      const entry: TFormDataEntry = value as unknown as TFormDataEntry;
      if (key === 'bsn' && !bsnRequired) {
        continue;
      }
      if (key === 'repeatEmail' && predefinedData?.email?.isEditable === false) {
        continue;
      }
      if (!entry.validator) {
        continue;
      }
      if (!entry.validator()) {
        valid = false;
        console.log('failed:' + key);
      }
    }
    return valid;
  };

  const handleOnPrevious = () => {
    setPageError(undefined);
    setProgress(progress - 1);
  };

  const handleOnNext = () => {
    setPageError(undefined);
    if (progress === EProgress.VALIDATION) {
      if (!vignetCode) {
        setPageError('Vul uw vignetnummer in.');
        return;
      }
      if (!activationCode) {
        setPageError('Vul uw activatiecode in.');
        return;
      }
      if (activationCode.trim() === '0') {
        setPageError('Vul een valide activatiecode in.');
        return;
      }

      VignetApi.activateVignet(
        vignetCode,
        activationCode,
        (e: TCheckVignet) => {
          if (e.fields.email) {
            e.fields.repeatEmail = e.fields.email;
          }

          setProgress(EProgress.PERSONAL_DETAILS);
          window.localStorage.setItem('access_token', e.access_token);
          setCountry(e.fields.country_id.value);
          setPredefinedData(e.fields);
          setCheckVignet(e);
          DataApi.getActivateDriveTypes(
            (driveTypes: TDriveType[]) => {
              setDriveTypes(driveTypes);
            },
            (error: Error) => {
              console.error(error);
            },
          );
          DataApi.getActivateObjectTypes(
            (objectTypes: TObjectType[]) => {
              setObjectTypes(objectTypes);
            },
            (error: Error) => {
              console.error(error);
            },
          );

          setObjectLength(e?.fields?.object_length);
          setObjectWidth(e?.fields?.object_width);
          setObjectDriveType(e?.fields?.object_drive_type_id);
          setObjectType(e?.fields?.object_type_id);
          setObjectName(e?.fields?.object_name);

          setFileCount(e?.photos_count || 0);
          setUploadRequired(e?.photos_required || false);

          setValid(true);
          return undefined;
        },
        (error: Error) => {
          setPageError(error.message);
        },
      );
      return;
    }
    if (progress === EProgress.PERSONAL_DETAILS) {
      if (!validateFormData()) {
        setPageError('U heeft één of meerdere van de velden niet correct ingevuld');
        setValid(false);
        return;
      }

      VignetApi.validateOwner(
        formData.initials.value,
        formData.prefix.value,
        formData.last_name.value,
        formData.email.value,
        predefinedData?.email?.isEditable ? formData?.repeatEmail?.value : formData?.email?.value,
        formData?.bsn?.value || '',
        formData.mobile_phone.value,
        checkVignet?.vignet_type_id || '',
        country || '',
        formData.address.value,
        formData.addition.value,
        formData.city.value,
        formData.number.value,
        formData.postcode.value,
        (json: any) => {
          setProgress(EProgress.OBJECT_DETAILS);
          setValid(true);
        },
        (error: Error) => {
          setPageError(error.message);
        },
      );
      return;
    }
    if (progress === EProgress.OBJECT_DETAILS) {
      if (uploadRequired) {
        if (fileUploadBoat?.length <= 0) {
          setPageError("Voeg de ontbrekende foto's toe.");
          setValid(false);
          return;
        }
        if (fileCount > 1 && fileUploadVignet?.length <= 0) {
          setPageError("Voeg de ontbrekende foto's toe.");
          setValid(false);
          return;
        }
      }

      if (!objectName.value) {
        setPageError('Geef een naam voor uw boot op.');
        setValid(false);
        return;
      }

      if (!objectDriveType.value) {
        setPageError('Geef een soort motor op.');
        setValid(false);
        return;
      }

      if (!objectType.value) {
        setPageError('Geef het soort boot op.');
        setValid(false);
        return;
      }

      VignetApi.validateObject(
        objectDriveType.value,
        objectWidth.value,
        objectLength.value,
        objectName.value,
        objectType.value,
        (json: any) => {
          setProgress(EProgress.SUMMARY);
          setValid(true);
        },
        (error: Error) => {
          setPageError(error.message);
          setValid(false);
        },
      );
      return;
    }

    if (progress == EProgress.SUMMARY) {
      if (!hasAgreedEndUser || !hasAgreedTruthfully) {
        setPageError('Ga akkoord met het bovenstaande om verder te gaan.');
        setValid(false);
        return;
      }
      setWorking(true);
      if (!uploadRequired && fileUploadBoat.length <= 0 && fileUploadVignet.length <= 0) {
        VignetApi.saveData(
          formData.initials.value,
          formData.prefix.value,
          formData.last_name.value,
          formData.email.value,
          predefinedData?.email?.isEditable ? formData?.repeatEmail?.value : formData?.email?.value,
          formData?.bsn?.value,
          formData.mobile_phone.value,
          'Doorvaart',
          country || '',
          formData.address.value,
          formData.addition.value,
          formData.city.value,
          formData.number.value,
          formData.postcode.value,
          objectDriveType.value,
          objectType.value,
          objectLength.value,
          objectWidth.value,
          objectName.value,
          (json: any) => {
            setWorking(false);
            setProgress(EProgress.THANK_YOU);
            setValid(true);
          },
          (error: Error) => {
            setWorking(false);
            setPageError(error.message);
          },
        );
      } else {
        VignetApi.uploadPhotos(
          [fileUploadBoat, fileUploadVignet],
          (json: any) => {
            VignetApi.saveData(
              formData.initials.value,
              formData.prefix.value,
              formData.last_name.value,
              formData.email.value,
              predefinedData?.email?.isEditable
                ? formData?.repeatEmail?.value
                : formData?.email?.value,
              formData?.bsn?.value,
              formData.mobile_phone.value,
              'Doorvaart',
              country || '',
              formData.address.value,
              formData.addition.value,
              formData.city.value,
              formData.number.value,
              formData.postcode.value,
              objectDriveType.value,
              objectType.value,
              objectLength.value,
              objectWidth.value,
              objectName.value,
              (json: any) => {
                setWorking(false);
                setProgress(EProgress.THANK_YOU);
                setValid(true);
              },
              (error: Error) => {
                setWorking(false);
                setPageError(error.message);
              },
            );
          },
          (error: Error) => {
            setWorking(false);
            setPageError(error.message);
          },
        );
      }
    }
  };

  const handleOnChangeCountry = (country: string, index: number, name: string) => {
    setBsnRequired(index === 0);
    setCountry(country);
    setCountryName(name);
  };

  if (progress === EProgress.THANK_YOU) {
    const getThankYouHeader = () => {
      return (
        <div id={style.subHeader}>
          U ontvangt een e-mail met een bevestiging van de activatie van uw vignet.<br></br>U kunt
          de samenvatting hieronder downloaden.
        </div>
      );
    };

    const getThankYouFooter = () => {
      return (
        <div id={style.subHeader}>
          Bel het telefoonnummer 14 020, maandag tot en met vrijdag van 08.00 tot 18.00 uur. Of
          gebruik het contactformulier op de website.<br></br>
          Houd uw vignetnummer bij de hand.
        </div>
      );
    };

    const handleOnDownloadSummary = () => {
      DataApi.getActivationSummary(
        (json: any) => {
          window.open(json.summary_link);
        },
        (error: Error) => {
          console.error(error);
        },
      );
    };

    return (
      <React.Fragment>
        <PageHeader logout={false} className={style.pageHeader}></PageHeader>
        <div id={style.container}>
          <CenteredForm>
            <TitleLineComponent text={vignetCode + ' activeren'} />
            <div id={style.header}>Bedankt</div>
            {getThankYouHeader()}
            <div className={style.pageSubHeader}>Samenvatting</div>
            <div></div>
            <DownloadSummaryButton onClick={handleOnDownloadSummary}></DownloadSummaryButton>
            <div className={style.pageSubHeader}>Vragen?</div>
            {getThankYouFooter()}
          </CenteredForm>
        </div>
      </React.Fragment>
    );
  }

  const getDriveTypeById = (id: string) => {
    return driveTypes?.find((driveType: TDriveType) => {
      return driveType.id === id;
    });
  };

  const getObjectTypeById = (id: string) => {
    return objectTypes?.find((objectType: TDriveType) => {
      return objectType.id === id;
    });
  };

  if (progress === EProgress.SUMMARY) {
    return (
      <React.Fragment>
        <PageHeader className={style.pageHeader}></PageHeader>
        <div id={style.container}>
          <FormStepper steps={steps} currentStep={3}></FormStepper>
          <CenteredForm
            onPrevious={() => {
              handleOnPrevious();
            }}
            onNext={() => {
              handleOnNext();
            }}
            locked={working}
          >
            <TitleLineComponent text={vignetCode + ' activeren'} />
            <div id={style.header}>Samenvatting</div>
            <br></br>
            <EditHeader
              text={'Uw gegevens'}
              onEdit={() => {
                setPageError(undefined);
                setProgress(EProgress.PERSONAL_DETAILS);
              }}
              hideEdit={predefinedData?.email?.isEditable === false}
            ></EditHeader>
            {bsnRequired ? (
              <KeyValuePair label={'BSN'} value={formData.bsn.value}></KeyValuePair>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <KeyValuePair
              label={'Uw voorletters'}
              value={formData?.initials.value || ''}
            ></KeyValuePair>
            <KeyValuePair
              label={'Tussenvoegsel'}
              value={formData?.prefix.value || ''}
            ></KeyValuePair>
            <KeyValuePair
              label={'Achternaam'}
              value={formData?.last_name.value || ''}
            ></KeyValuePair>
            <KeyValuePair label={'Straat'} value={formData?.address.value || ''}></KeyValuePair>
            <KeyValuePair
              label={'Huisnummer'}
              value={formData?.number.value || ''}
              inline={true}
            ></KeyValuePair>
            <KeyValuePair
              label={'Toevoeging'}
              value={formData?.addition.value || ''}
              inline={true}
            ></KeyValuePair>
            <KeyValuePair
              label={'Postcode'}
              value={formData?.postcode.value || ''}
              inline={true}
            ></KeyValuePair>
            <KeyValuePair
              label={'Woonplaats'}
              value={formData?.city.value || ''}
              inline={true}
            ></KeyValuePair>
            <KeyValuePair label={'Land'} value={countryName || ''} inline={false}></KeyValuePair>
            <KeyValuePair
              label={'Telefoonnummer'}
              value={formData?.mobile_phone.value || ''}
            ></KeyValuePair>
            <KeyValuePair label={'E-mailadres'} value={formData?.email.value || ''}></KeyValuePair>
            <EditHeader
              text={'Gegevens van uw boot'}
              onEdit={() => {
                setPageError(undefined);
                setProgress(EProgress.OBJECT_DETAILS);
              }}
            ></EditHeader>
            <KeyValuePair label={'Naam boot'} value={objectName.value}></KeyValuePair>
            <KeyValuePair label={'Vignetnummer'} value={vignetCode || ''}></KeyValuePair>
            <KeyValuePair label={'Lengte boot'} value={objectLength.value}>
              meter
            </KeyValuePair>
            <KeyValuePair label={'Breedte boot'} value={objectWidth.value}>
              meter
            </KeyValuePair>
            <KeyValuePair
              label={'Soort motor'}
              value={getDriveTypeById(objectDriveType.value)?.name || ''}
            ></KeyValuePair>
            <KeyValuePair
              label={'Soort boot'}
              value={getObjectTypeById(objectType.value)?.name || ''}
            ></KeyValuePair>
            <br></br>

            <AgreementBox
              defaultChecked={hasAgreedTruthfully}
              validity={isValid || hasAgreedTruthfully}
              text={
                'Ik heb alle gegevens correct en volledig ingevuld. Ik begrijp dat een aanvraag via internet dezelfde juridische status heeft als een aanvraag met een geschreven geldige handtekening.'
              }
              onChange={(checked: boolean) => {
                setHasAgreedTruthfully(checked);
              }}
            ></AgreementBox>
            <AgreementBox
              defaultChecked={hasAgreedEndUser}
              validity={isValid || hasAgreedEndUser}
              text={
                'Ik ben op de hoogte van de regels die op het water van Amsterdam gelden, zoals ze staan beschreven in de Binnenhavengeldverordening Pleziervaart en de Verordening op het binnenwater 2010. Kijk op '
              }
              onChange={(checked: boolean) => {
                setHasAgreedEndUser(checked);
              }}
            >
              <a target={'_blank'} href="https://www.amsterdam.nl/varen" rel="noreferrer">
                "https://www.amsterdam.nl/varen"{' '}
              </a>
              <div>voor meer informatie.</div>
            </AgreementBox>

            {pageError ? (
              <div id={style.pageError}>{pageError}</div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </CenteredForm>
        </div>
      </React.Fragment>
    );
  }

  const required = (value: string) => {
    setAction(action + 1);
    return value && value.trim().length > 0;
  };

  const isValidPhoneNumber = () => {
    setAction(action + 1);
    return /^\+??\d{3}?[-\s\.]?\d{3}[-\s\.]?\d{4,9}$/im.test(formData.mobile_phone.value);
  };

  const isSamePhoneNumber = () => {
    setAction(action + 1);
    if (formData.repeatPhoneNumber.value.length <= 0) {
      return false;
    }
    if (formData.phoneNumber.value === formData.repeatPhoneNumber.value) {
      setValid(true);
    }
    return formData.phoneNumber.value === formData.repeatPhoneNumber.value;
  };

  const isValidEmail = () => {
    setAction(action + 1);
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.value);
  };

  const isSameEmail = () => {
    setAction(action + 1);
    if (formData.repeatEmail.value.length <= 0) {
      return false;
    }
    if (formData.email.value === formData.repeatEmail.value) {
      setValid(true);
    }
    return formData.email.value === formData.repeatEmail.value;
  };

  const formatPostalCode = (value: string | React.ChangeEvent<HTMLInputElement>): string => {
    let formattedPostalCode = '';

    if (typeof value === 'string') {
      formattedPostalCode = value;
    } else {
      formattedPostalCode = value.target.value;
    }

    formattedPostalCode = formattedPostalCode.replace(
      /^(\d{0,4})\s*([a-zA-Z]{0,2}).*$/,
      (match, digits, letters) => {
        if (String(digits).length !== 4) {
          return digits;
        } else {
          return `${digits} ${letters.toUpperCase()}`;
        }
      },
    );
    formattedPostalCode = formattedPostalCode.replace(/[^a-zA-Z0-9]/g, '');

    if (formattedPostalCode.length <= 4) {
      formattedPostalCode = formattedPostalCode.slice(0, 4);
    } else {
      formattedPostalCode =
        formattedPostalCode.slice(0, 4) + ' ' + formattedPostalCode.slice(4, 6).toUpperCase();
    }

    if (typeof value !== 'string') {
      value.target.value = formattedPostalCode;
    }

    return formattedPostalCode;
  };

  const isDutchPostalCode = (postalCode: string): boolean => {
    const trimmedPostalCode = postalCode.trim();

    // Regular expression for Dutch postal codes (case-insensitive)
    const regex = /^[1-9][0-9]{3}\s?[A-Z]{2}$/i;

    return regex.test(trimmedPostalCode);
  };

  // const isValidZipCode = () => {
  //   setAction(action + 1);
  //   return /^\d{4}[a-z]{2}$/.test(formData.postcode.value.toLowerCase());
  // };

  const isValidHouseNumber = () => {
    setAction(action + 1);
    if (formData.number.value.toLowerCase().trim().length <= 0) {
      return false;
    }
    return /^[0-9]*$/.test(formData.number.value.toLowerCase());
  };

  const isBsnNumber = (value: string) => {
    setAction(action + 1);
    if (value.length < 8 || value.length > 9) {
      return false;
    }
    const numbers: string[] = value.split('');
    let factor: number = numbers.length;
    let total: number = 0;
    numbers.forEach((number: string) => {
      if (factor == 1) {
        factor = -1;
      }
      total += parseInt(number) * factor--;
    });

    const dividend = (total /= 11);

    if (dividend === 0 || Math.round(dividend) !== dividend) {
      return false;
    }
    setValid(true);
    return true;
  };

  const readFile = async (file: any) => {
    return await new Promise((resolve: any) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve({
          name: file.name,
          data: (fileReader.result as string).split(';base64,', 2)[1],
          native: file,
        });
      };
      fileReader.readAsDataURL(file);
    });
  };

  const handleOnChangeFileUploadBoat = async (e: any) => {
    const mimeType: string = e.target.files[0].type;
    if (!mimeType.startsWith('image')) {
      setPageError('Alleen bestanden van het type png en jpg zijn toegestaan.');
      return;
    }
    setPageError(undefined);
    const files: TFile[] = [];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push((await readFile(e.target.files[i])) as TFile);
    }
    setAction(action + 1);
    setFileUploadBoat(files);
    setValid(true);
  };

  const handleOnChangeFileUploadVignet = async (e: any) => {
    const mimeType: string = e.target.files[0].type;
    if (!mimeType.startsWith('image')) {
      setPageError('Alleen bestanden van het type png en jpg zijn toegestaan.');
      return;
    }
    setPageError(undefined);
    const files: TFile[] = [];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push((await readFile(e.target.files[i])) as TFile);
    }
    setFileUploadVignet(files);
    setValid(true);
  };

  if (progress === EProgress.OBJECT_DETAILS) {
    const buildFields = () => {
      if (!checkVignet) {
        return <React.Fragment></React.Fragment>;
      }
      const fields = [];
      for (const [key, value] of Object.entries(checkVignet.fields)) {
        formData[key as string] = value;
        fields.push(
          <LabeledInputField
            validator={undefined}
            text={key}
            name={key}
            formData={formData}
          ></LabeledInputField>,
        );
      }
      return fields;
    };

    return (
      <React.Fragment>
        <PageHeader className={style.pageHeader}></PageHeader>
        <div id={style.container}>
          <FormStepper steps={steps} currentStep={2}></FormStepper>
          <CenteredForm onPrevious={handleOnPrevious} onNext={handleOnNext}>
            <TitleLineComponent text={vignetCode + ' activeren'} />
            <div id={style.header}>Gegevens van uw boot</div>
            <div id={style.subHeader}>Wij hebben de volgende informatie van u nodig:</div>
            <div className={style.objectDetails}>
              <div className={style.objectDetailsText}>
                Lengte boot <label>(in meters)</label>
              </div>
              <input
                value={objectLength.value}
                disabled={objectLength.isEditable === false}
                onChange={(e: any) => {
                  setObjectLength({ value: e.target.value, isEditable: objectLength.isEditable });
                }}
                className={
                  isValid || objectLength.value ? style.objectInputValid : style.objectInputInvalid
                }
              ></input>
            </div>

            <div className={style.objectDetails}>
              <div className={style.objectDetailsText}>
                Breedte boot <label>(in meters)</label>
              </div>
              <input
                value={objectWidth.value}
                disabled={objectLength.isEditable === false}
                onChange={(e: any) => {
                  setObjectWidth({ value: e.target.value, isEditable: objectWidth.isEditable });
                }}
                className={
                  isValid || objectWidth.value ? style.objectInputValid : style.objectInputInvalid
                }
              ></input>
            </div>
            <br></br>
            <div className={style.objectDetails}>
              <div className={style.objectDetailsText}>Soort motor</div>
              <select
                value={objectDriveType.value}
                disabled={objectDriveType.isEditable === false}
                onChange={(e: any) => {
                  setObjectDriveType({
                    value: e.target.value,
                    isEditable: objectDriveType.isEditable,
                  });
                }}
                className={
                  isValid || objectDriveType.value
                    ? style.objectInputLongValid
                    : style.objectInputLongInvalid
                }
              >
                <option></option>
                {driveTypes?.map((driveType: TDriveType, index: number) => {
                  return (
                    <option key={'drive_type_option_' + index} value={driveType.id}>
                      {driveType.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={style.objectDetails}>
              <div className={style.objectDetailsText}>Soort boot</div>
              <select
                value={objectType.value}
                disabled={objectType.isEditable === false}
                onChange={(e: any) => {
                  setObjectType({ value: e.target.value, isEditable: objectType.isEditable });
                }}
                className={
                  isValid || objectType.value
                    ? style.objectInputLongValid
                    : style.objectInputLongInvalid
                }
              >
                <option></option>
                {objectTypes?.map((objectType: TObjectType, index: number) => {
                  return (
                    <option key={'object_type_option_' + index} value={objectType.id}>
                      {objectType.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className={style.objectDetails}>
              <div className={style.objectDetailsText}>Naam boot</div>
              <input
                value={objectName.value}
                style={{ height: '40px' }}
                disabled={objectName.isEditable === false}
                onChange={(e: any) => {
                  setObjectName({ value: e.target.value, isEditable: objectName.isEditable });
                }}
                className={
                  isValid || objectName.value
                    ? style.objectInputLongValid
                    : style.objectInputLongInvalid
                }
              ></input>
            </div>

            <div className={style.pageHeader}>
              Voeg hier een foto toe waarop uw volledige boot zichtbaar is.
            </div>
            <UploadField
              accept={'image/png, image/jpeg'}
              singleFileText={true}
              removeFile={() => {
                setFileUploadBoat([]);
              }}
              onChange={handleOnChangeFileUploadBoat}
              validity={!uploadRequired || isValid || fileUploadBoat?.length > 0}
              files={fileUploadBoat}
            ></UploadField>

            {fileCount === 2 ? (
              <div className={style.pageHeader}>
                Voeg hier een foto toe van uw boot waarop het vignet zichtbaar is.
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {fileCount === 2 ? (
              <UploadField
                accept={'image/png, image/jpeg'}
                singleFileText={true}
                removeFile={() => {
                  setFileUploadVignet([]);
                }}
                onChange={handleOnChangeFileUploadVignet}
                validity={!uploadRequired || isValid || fileUploadVignet?.length > 0}
                files={fileUploadVignet}
              ></UploadField>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            <div id={style.pageError}>{pageError}</div>
          </CenteredForm>
        </div>
      </React.Fragment>
    );
  }

  if (progress === EProgress.PERSONAL_DETAILS) {
    return (
      <React.Fragment>
        <PageHeader className={style.pageHeader}></PageHeader>
        <div id={style.container}>
          <FormStepper steps={steps} currentStep={1}></FormStepper>
          <CenteredForm onPrevious={handleOnPrevious} onNext={handleOnNext}>
            <TitleLineComponent text={vignetCode + ' activeren'} />
            <div className={style.pageHeader}>Uw gegevens</div>
            <div id={style.subHeader}>
              Dit zijn uw gegevens die bij ons bekend zijn.<br></br>
              Wij kunnen hiermee in geval van nood contact met u opnemen.<br></br>
              Wij gebruiken deze gegevens ook om u een bevestiging te sturen.
            </div>
            <LabeledInputField
              predefinedData={predefinedData}
              validator={required}
              text={'Voorletter(s)'}
              name={'initials'}
              formData={formData}
            ></LabeledInputField>
            <LabeledInputField
              predefinedData={predefinedData}
              validator={undefined}
              text={'Tussenvoegsel'}
              name={'prefix'}
              formData={formData}
            ></LabeledInputField>
            <LabeledInputField
              predefinedData={predefinedData}
              validator={required}
              text={'Achternaam'}
              name={'last_name'}
              formData={formData}
            ></LabeledInputField>
            <LabeledInputField
              predefinedData={predefinedData}
              validator={required}
              text={'Straat'}
              name={'address'}
              formData={formData}
            ></LabeledInputField>
            <LabeledInputField
              predefinedData={predefinedData}
              validator={isValidHouseNumber}
              text={'Huisnummer'}
              name={'number'}
              formData={formData}
            ></LabeledInputField>
            <LabeledInputField
              predefinedData={predefinedData}
              validator={undefined}
              text={'Toevoeging'}
              name={'addition'}
              formData={formData}
            ></LabeledInputField>
            <LabeledInputField
              predefinedData={predefinedData}
              validator={bsnRequired ? isDutchPostalCode : required}
              text={'Postcode'}
              name={'postcode'}
              formData={formData}
            ></LabeledInputField>
            <LabeledInputField
              predefinedData={predefinedData}
              validator={required}
              text={'Woonplaats'}
              name={'city'}
              formData={formData}
            ></LabeledInputField>

            <CountrySelectionField
              text={'Land'}
              valid={!!country || isValid}
              onChange={handleOnChangeCountry}
              defaultValue={country || ''}
              disabled={!predefinedData?.city?.isEditable}
            ></CountrySelectionField>

            {bsnRequired ? (
              <LabeledInputField
                predefinedData={predefinedData}
                validator={isBsnNumber}
                text={'BSN'}
                name={'bsn'}
                formData={formData}
              ></LabeledInputField>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <LabeledInputField
              predefinedData={predefinedData}
              validator={isValidPhoneNumber}
              text={'Telefoonnummer'}
              name={'mobile_phone'}
              formData={formData}
            ></LabeledInputField>
            <LabeledInputField
              predefinedData={predefinedData}
              validator={isValidEmail}
              text={'E-mailadres'}
              name={'email'}
              formData={formData}
            ></LabeledInputField>
            {predefinedData?.email?.isEditable ? (
              <LabeledInputField
                predefinedData={predefinedData}
                validator={isSameEmail}
                text={'Herhaal e-mailadres'}
                name={'repeatEmail'}
                formData={formData}
              ></LabeledInputField>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {!checkVignet?.photos_required ? (
              <h5>Velden met * zijn verplicht.</h5>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <div id={style.pageError}>{pageError}</div>
          </CenteredForm>
        </div>
      </React.Fragment>
    );
  }

  if (progress === EProgress.VALIDATION) {
    return (
      <React.Fragment>
        <PageHeader className={style.pageHeader}></PageHeader>
        <div id={style.container}>
          <FormStepper steps={steps} currentStep={0}></FormStepper>
          <CenteredForm onNext={handleOnNext}>
            <TitleLineComponent text={'Vignet activeren'} />
            <div id={style.header}>Vignetnummer en activatiecode invullen</div>
            <div id={style.subHeader}>
              Vul hieronder het nieuwe vignetnummer en de activatiecode in.<br></br>U vindt het
              vignetnummer op het fysieke vignet.<br></br>U hebt de activatiecode in een e-mail
              ontvangen.
            </div>

            <div className={style.validationLabel}>Vignetnummer</div>
            <input
              type="text"
              value={vignetCode}
              onChange={(e) => {
                setVignetCode(e.target.value);
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  handleOnNext();
                }
              }}
            ></input>
            <div className={style.validationLabel}>Activatiecode</div>
            <input
              type="password"
              value={activationCode}
              onChange={(e) => {
                setActivationCode(e.target.value);
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  handleOnNext();
                }
              }}
            ></input>

            <AlertBox
              severity={ESeverity.MEDIUM}
              bold={true}
              text={'Graag uw verlopen vignetten van uw boot verwijderen.'}
            ></AlertBox>

            <img id={style.exampleVignet} src="../examplevignet.png"></img>

            <div id={style.pageError}>{pageError}</div>

            <div id={style.disclaimer}>
              De gemeente Amsterdam gaat zorgvuldig en veilig met uw persoonsgegevens om. Wij houden
              ons daarbij aan de privacywetten en -regels. Dat betekent bijvoorbeeld dat wij uw
              gegevens alleen voor deze aanvraag gebruiken en niet voor iets anders. Meer informatie
              vindt u in de{' '}
              <a href="https://www.amsterdam.nl/privacy/specifieke/privacyverklaring-parkeren-verkeer-bouw/verkeersmanagement-gracht-privacy/">
                privacyverklaring Binnenhavengeld
              </a>
              .
            </div>
          </CenteredForm>
        </div>
      </React.Fragment>
    );
  }

  return <div>403 Forbidden.</div>;
};

export default ActivateVignetPage;
