import * as React from 'react';

import style from './ButtonNext.module.css';

type Props = {
  arrowMode?: boolean;
  children?: any;
  onClick: Function;
};

const ButtonNext = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [backgroundColor, setBackgroundColor] = React.useState('#EC0000');

  const onMouseEnter = () => {
    setBackgroundColor('#B90000');
  };
  const onMouseLeave = () => {
    setBackgroundColor('#EC0000');
  };

  const arrow = props.arrowMode ? (
    <div
      style={{ borderLeftColor: backgroundColor }}
      onClick={(e) => {
        props.onClick(e, setLoading);
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={style.arrowRight}
    ></div>
  ) : (
    ''
  );
  const buttonMode = props.arrowMode ? style.arrowMode : style.squareMode;
  return (
    <div id={style.container}>
      <div
        onClick={(e) => {
          if (!loading) {
            props.onClick(e, setLoading);
          }
        }}
        style={{ backgroundColor: backgroundColor }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`${style.buttonBase} ${buttonMode}`}
      >
        {loading ? 'Bezig..' : props.children}
      </div>
      {arrow}
    </div>
  );
};

export default ButtonNext;
