import * as React from 'react';
import { useNavigate } from 'react-router';

import style from './PageHeader.module.css';

type TPageHeader = {
  logout?: boolean;
  className?: string;
};

const PageHeader = (props: TPageHeader) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    window.localStorage.removeItem('access_token');
    navigate('/');
  };

  return (
    <div id={style.container} className={props.className}>
      <div id={style.logo}></div>
      <div id={style.bar}>
        {props.logout ? (
          <div id={style.logout} onClick={handleOnClick}>
            {'< Uitloggen'}
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
