import * as React from 'react';
import { useNavigate } from 'react-router';
import UserApi, { TOwner } from '../../api/UserApi';
import VignetApi, { TVignet } from '../../api/VignetApi';
import AgreementBox from '../../components/agreement-box/AgreementBox';
import CenteredForm from '../../components/centered-form/CenteredForm';
import KeyValuePair from '../../components/key-value-pair/KeyValuePair';
import PageHeader from '../../components/page-header/PageHeader';
import FormStepper, { TStep } from '../form-stepper/FormStepper';

import style from './PersonalInfoPage.module.css';

const PersonalInfoPage = () => {
  const [repeatEmail, setRepeatEmail] = React.useState<string | undefined>(undefined);

  const [pageError, setPageError] = React.useState<string | undefined>(undefined);

  const [vignets, setVignets] = React.useState<TVignet[] | undefined>(() => {
    VignetApi.getVignets(
      (json: any) => {
        setVignets(json.vignets);
      },
      (error: Error) => {
        setPageError(error.message);
      },
    );
    return undefined;
  });

  const [owner, setOwner] = React.useState<TOwner | undefined>(() => {
    UserApi.getOwner(
      (json: any) => {
        setOwner(json);
        setRepeatEmail(json.email);
      },
      (error: Error) => {
        console.error(error);
      },
    );
    return undefined;
  });

  const navigate = useNavigate();

  const [editable, setEditable] = React.useState<boolean>(false);

  const [validity, setValidity] = React.useState<boolean>(true);

  if (!owner) {
    return <div>Loading..</div>;
  }

  const steps: TStep[] = [
    { text: 'Uw gegevens' },
    { text: 'Vignetoverzicht' },
    { text: 'Uw vignet' },
    { text: 'Samenvatting' },
    { text: 'Bedankt' },
  ];

  const handleOnNext = () => {
    if (!vignets) {
      setPageError('Er is iets misgegaan bij het ophalen van uw vignets.');
      setValidity(false);
      return;
    }

    if (editable) {
      if (areFieldsValid()) {
        UserApi.updateOwner(
          owner.address,
          owner.number,
          owner.addition,
          owner.postcode,
          owner.city,
          owner.email,
          owner.mobilephone,
          owner.mobilephone,
          () => {
            navigate('/vignet-overview');
          },
          (error: Error) => {
            setPageError(error.message);
          },
        );
        return;
      } else {
        setPageError('Vul alle velden geheel en correct in.');
        return;
      }
    }
    navigate('/vignet-overview');
  };

  const isPhoneNumber = (value: string) => {
    return /^\+??\d{3}?[-\s\.]?\d{3}[-\s\.]?\d{4,6}$/im.test(value);
  };

  const isEmail = (value: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  };

  const isZipCode = (value: string) => {
    return /^\d{4}[A-Z]{2}$/.test(value);
  };

  const areFieldsValid = () => {
    let valid = true;
    if (owner.address.trim().length <= 0) {
      console.error('incorrect: Address ', owner.address);
      valid = false;
    }
    if (owner.number.trim().length <= 0) {
      console.error('incorrect: houseNumber ', owner.number);
      valid = false;
    }
    if (!isPhoneNumber(owner.mobilephone)) {
      console.error('incorrect: mobilePhone ', owner.mobilephone);
      valid = false;
    }
    if (!isZipCode(owner.postcode)) {
      console.error('incorrect: zipcode ', owner.postcode);
      valid = false;
    }
    if (owner.city.trim().length <= 0) {
      console.error('incorrect: city ', owner.city);
      valid = false;
    }
    if (!isEmail(owner.email)) {
      console.error('incorrect: email ', owner.email);
      valid = false;
    }
    if (owner.email !== repeatEmail) {
      console.error('missmatched emails: ', owner.email, repeatEmail);
      valid = false;
    }
    setValidity(valid);
    return valid;
  };

  const handleOnChangeAddress = (e: any) => {
    owner.address = e.target.value;
    return owner.address.trim().length > 0;
  };

  const handleOnChangeHouseNumber = (e: any) => {
    owner.number = e.target.value;
    return owner.number.trim().length > 0;
  };

  const handleOnChangeAddition = (e: any) => {
    owner.addition = e.target.value;
    return true;
  };

  const handleOnChangeZipcode = (e: any) => {
    owner.postcode = e.target.value;
    return isZipCode(owner.postcode);
  };

  const handleOnChangeCity = (e: any) => {
    owner.city = e.target.value;
    return owner.city.trim().length > 0;
  };

  const handleOnChangePhoneNumber = (e: any) => {
    owner.mobilephone = e.target.value;
    return isPhoneNumber(owner.mobilephone);
  };

  const handleOnChangeEmail = (e: any) => {
    owner.email = e.target.value;
    return owner.email === repeatEmail && isEmail(owner.email);
  };

  const handleOnChangeRepeatEmail = (e: any) => {
    setRepeatEmail(e.target.value);
    return owner.email === e.target.value && isEmail(e.target.value);
  };

  return (
    <React.Fragment>
      <PageHeader logout={true} className={style.pageHeader}></PageHeader>
      <div id={style.container}>
        <FormStepper steps={steps} currentStep={0}></FormStepper>
        <CenteredForm
          onNext={() => {
            handleOnNext();
          }}
        >
          <div id={style.header}>Uw persoonlijke gegevens</div>
          <div id={style.subHeader}>Dit zijn uw gegevens die bekend zijn in ons systeem.</div>
          <KeyValuePair label={'BSN'} value={owner.bsn}></KeyValuePair>
          <KeyValuePair label={'Uw voorletters'} value={owner.initials}></KeyValuePair>
          <KeyValuePair label={'Tussenvoegsel'} value={owner.prefix}></KeyValuePair>
          <KeyValuePair label={'Achternaam'} value={owner.lastname}></KeyValuePair>
          <KeyValuePair
            label={'Uw contactgegevens'}
            value={
              'Hieronder ziet u de contactgegevens die wij van u hebben. Kloppen de gegevens niet? Stuur een e-mail naar vaarvignet@amsterdam.nl'
            }
          ></KeyValuePair>
          <br></br>
          <KeyValuePair
            editable={editable ? handleOnChangeAddress : undefined}
            label={'Straat'}
            value={owner.address}
          ></KeyValuePair>
          <KeyValuePair
            editable={editable ? handleOnChangeHouseNumber : undefined}
            label={'Huisnummer'}
            value={owner.number}
            inline={true}
          ></KeyValuePair>
          <KeyValuePair
            editable={editable ? handleOnChangeAddition : undefined}
            label={'Toevoeging'}
            value={owner.addition}
            inline={true}
          ></KeyValuePair>
          <KeyValuePair
            editable={editable ? handleOnChangeZipcode : undefined}
            label={'Postcode'}
            value={owner.postcode}
            inline={true}
          ></KeyValuePair>
          <KeyValuePair
            editable={editable ? handleOnChangeCity : undefined}
            label={'Woonplaats'}
            value={owner.city}
            inline={true}
          ></KeyValuePair>
          <KeyValuePair
            editable={editable ? handleOnChangePhoneNumber : undefined}
            label={'Telefoonnummer'}
            value={owner.mobilephone}
          ></KeyValuePair>
          <KeyValuePair
            editable={editable ? handleOnChangeEmail : undefined}
            label={'E-mailadres'}
            value={owner.email}
          ></KeyValuePair>
          {pageError ? (
            <div id={style.pageError}>{pageError}</div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </CenteredForm>
      </div>
    </React.Fragment>
  );
};

export default PersonalInfoPage;
