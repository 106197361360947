import * as React from 'react';

import style from './LabeledInputField.module.css';

type TFormDataEntry = {
  valid: boolean;
  value: string;
  validator?: Function;
};

type TLabeledInputField = {
  text: string;
  name: string;
  formData: any;
  predefinedData?: any;
  validator?: Function;
  disabled?: boolean;
};

const LabeledInputField = (props: TLabeledInputField) => {
  const validate = () => {
    if (!props.validator) {
      props.formData[props.name].valid = true;
      return true;
    }
    props.formData[props.name].valid = !!props.validator(props.formData[props.name].value);
    return props.formData[props.name].valid;
  };

  if (!props.formData[props.name]) {
    const entry: TFormDataEntry = {
      valid: true,
      value: props.predefinedData?.[props.name]?.value || '',
      validator: validate,
    };
    props.formData[props.name] = entry;
  }

  props.formData[props.name].validator = () => {
    if (!props.validator) {
      props.formData[props.name].valid = true;
      return true;
    }
    props.formData[props.name].valid = !!props.validator(props.formData[props.name].value);
    return props.formData[props.name].valid;
  };

  const handleOnChange = (e: any) => {
    props.formData[props.name].value = e.target.value;
    if (props.validator) {
      props.formData[props.name].valid = props.validator(e.target.value);
    }
  };

  return (
    <div id={style.container}>
      <div id={style.label}>
        {props.text}{' '}
        {props.validator && props.predefinedData?.[props.name]?.isEditable !== false ? '*' : ''}
      </div>
      <input
        id={style.input}
        className={props.formData[props.name].valid ? style.valid : style.invalid}
        type={'text'}
        name={props.name}
        defaultValue={props.formData[props.name].value || ''}
        onChange={handleOnChange}
        disabled={props.disabled || props.predefinedData?.[props.name]?.isEditable === false}
      ></input>
    </div>
  );
};

export default LabeledInputField;
