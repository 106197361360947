import * as React from 'react';
import { useNavigate } from 'react-router';
import UserApi from '../../api/UserApi';

import style from './Login.module.css';

type TLogin = {
  className?: string;
};

const Login = (props: TLogin) => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined>(undefined);

  const [emailError, setEmailError] = React.useState<string | undefined>(undefined);
  const [passwordError, setPasswordError] = React.useState<string | undefined>(undefined);
  const [loginError, setLoginError] = React.useState<string | undefined>(undefined);

  const isValidEmail = (email: string) => {
    if (email.length <= 0) {
      setEmailError('Het veld e-mailadres is niet ingevuld');
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Het e-mailadres in het veld hierboven is onjuist ingevuld');
      return false;
    }
    setEmailError(undefined);
    return true;
  };

  const isValidPassword = (password: string) => {
    if (password.length <= 0) {
      setPasswordError('Het veld wachtwoord is niet ingevuld');
      return false;
    }
    setPasswordError(undefined);
    return true;
  };

  const handleOnChangeEmail = (e: any) => {
    setEmail(e.target.value.trim());
    isValidEmail(e.target.value.trim());
  };

  const handleOnChangePassword = (e: any) => {
    setPassword(e.target.value.trim());
    isValidPassword(e.target.value.trim());
  };

  const handleOnClick = () => {
    const emailValidity = isValidEmail(email || '');
    const passwordValidity = isValidPassword(password || '');

    if (!emailValidity || !passwordValidity) {
      return;
    }

    UserApi.login(
      email as string,
      password as string,
      (json: any) => {
        window.localStorage.setItem('access_token', json.access_token);
        navigate('/personal-info');
      },
      (error: Error) => {
        setLoginError(error.message);
      },
    );
  };

  return (
    <div id={style.container} className={props.className}>
      <div id={style.header}>Welkom</div>
      <div id={style.description}>
        Met dit formulier kunt u uw binnenhavengeldvignet wijzigen of opzeggen.
      </div>
      <div id={style.subHeader}>Inloggen</div>
      <label className={style.label}>E-mailadres</label>
      <input
        className={style.input}
        type="email"
        name="email"
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            handleOnClick();
          }
        }}
        onChange={handleOnChangeEmail}
      ></input>
      {emailError ? <div className={style.error}>{emailError}</div> : ''}
      <label className={style.label}>Wachtwoord</label>
      <input
        className={style.input}
        type="password"
        name="password"
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            handleOnClick();
          }
        }}
        onChange={handleOnChangePassword}
      ></input>
      {passwordError ? <div className={style.error}>{passwordError}</div> : ''}
      <div id={style.forgotPasswordContainer}>
        <div
          id={style.forgotPassword}
          onClick={() => {
            navigate('/reset-password');
          }}
        >
          Wachtwoord vergeten?
        </div>
      </div>
      {loginError ? <div className={style.error}>{loginError}</div> : ''}
      <button id={style.submitButton} onClick={handleOnClick}>
        Login
      </button>
    </div>
  );
};

export default Login;
