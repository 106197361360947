import * as React from 'react';

import style from './AlertBox.module.css';

enum ESeverity {
  LOW,
  MEDIUM,
  HIGH,
}

type TAlertBox = {
  header?: string;
  text: string;
  severity?: ESeverity;
  bold?: boolean;
};

const AlertBox = (props: TAlertBox) => {
  const serverityStyling =
    props.severity === ESeverity.LOW
      ? style.low
      : props.severity === ESeverity.MEDIUM
        ? style.medium
        : props.severity === ESeverity.HIGH
          ? style.high
          : style.medium;
  return (
    <div id={style.container} className={serverityStyling}>
      {props.header ? (
        <div className={style.header}>{props.header}</div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <div className={props.bold ? style.boldText : style.text}>{props.text}</div>
    </div>
  );
};

export default AlertBox;
export { ESeverity };
